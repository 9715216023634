import morphdom from 'morphdom'

window.morph = (fromNode, toNode) => {
  morphdom(fromNode, toNode, {
    onBeforeElUpdated: function(fromElement, toElement) {
      if (fromElement === document.activeElement) {
        return false
      } else {
        return true
      }
    }
  })
}
